import axiosService from "./axiosService";
import {
  normalize,
  onProduction,
  sort,
  translateX,
  clone,
  returnOnlyNumbers,
} from "../utils/helpers";
import dayjs from "dayjs";
import {
  MdOutlineCalendarToday,
  MdOutlineElderly,
  MdOutlineLuggage,
  MdOutlineMedicalServices,
  MdPerson,
} from "react-icons/md";
import financialService from "./financialService";
import { Switch } from "antd";
import { API_ORDERS_URL } from "../settings/constant";
import appService from "./appService";
import receivableService from "./receivableService";
import orderService from "./orderService";
import entityService from "./entityService";

let parameters;

export let HOME_BACKGROUND_URL = "/images/banner/fallback.jpg";
export let HOME_BANNER_URL; // = "/images/banner/banner-example.jpg";
export let HOME_BANNER_COLOR = "#000000";
export let TRAVELERS_WARNING_URL = "";

const appmultiService = {};

appmultiService.loadParameters = async () => {
  // parameters
  let axiosOptions = {
    url: "/v1/appmulti/parameters",
    returnAsObject: true,
  };
  let [doc] = await axiosService.get(axiosOptions);
  parameters = doc;

  // website config
  axiosOptions = {
    url: "/v1/webconfig/get-list",
    returnAsObject: true,
  };
  [doc] = await axiosService.get(axiosOptions);

  if (doc?.home?.backgroundUrl) HOME_BACKGROUND_URL = doc.home.backgroundUrl;
  if (doc?.home?.bannerUrl) HOME_BANNER_URL = doc.home.bannerUrl;
  if (doc?.home?.bannerColor) HOME_BANNER_COLOR = doc.home.bannerColor;
  if (doc?.travelersWarningUrl) TRAVELERS_WARNING_URL = doc.travelersWarningUrl;
};

appmultiService.getDestinations = (titleFromLabel) => {
  const destinations = onProduction()
    ? parameters?.destinations?.filter((x) => x.value !== "test")
    : parameters?.destinations;

  return destinations
    ?.map((d) => {
      const text = translateX(d.text);
      const label = translateX(d.label);

      return {
        value: d.value,
        text: normalize(text, "lower"),
        label: titleFromLabel ? text : label,
        tooltipText: translateX(d.tooltipText),
        disabled: false,
      };
    })
    ?.sort((a, b) => sort(a, b, "text"));
};

appmultiService.getTravelProfiles = (titleFromLabel) => {
  return parameters?.travelProfiles
    ?.map((d) => {
      const text = translateX(d.text);
      const label = translateX(d.label);

      return {
        value: d.value,
        text: normalize(text, "lower"),
        label: titleFromLabel ? text : label,
        tooltipText: translateX(d.tooltipText),
        disabled: false,
      };
    })
    .sort((a, b) => sort(a, b, "text"));
};

appmultiService.getPlans = async (
  destiny,
  travelPeriodArr,
  passengersArr,
  highlightStyle,
) => {
  const departure = travelPeriodArr?.[0]?.format("YYYY-MM-DD");
  const arrival = travelPeriodArr?.[1]?.format("YYYY-MM-DD");
  const days =
    departure && arrival
      ? dayjs(travelPeriodArr[1]).diff(travelPeriodArr[0], "days") + 1
      : 0;

  if (
    !destiny ||
    !departure ||
    !arrival ||
    !days ||
    !passengersArr ||
    !Array.isArray(passengersArr) ||
    passengersArr.length <= 0
  ) {
    return;
  }

  const axiosOptions = { url: `/v1/plan/get-by-destiny/${destiny}` };
  axiosOptions.qs = { passengers: passengersArr, days };

  const [data] = await axiosService.get(axiosOptions);

  const docs = clone(data);

  const plansArr = docs?.map((doc) =>
    appmultiService.decoratePlan({
      plan: doc,
      days,
      passengersArr,
      highlightStyle,
    }),
  );

  return appmultiService.sorterPlans(plansArr);
};

appmultiService.decoratePlan = ({
  plan,
  days,
  passengersArr,
  highlightStyle = "operator",
  adminDiscount,
  adminAdjustment,
}) => {
  if (plan?.priceType === "table") {
    const pday = plan?.priceTable?.find((t) => t.from <= days && t.to >= days);
    plan.price = pday?.value || 0;
  }

  plan.summary = appmultiService.calculatePlanValues({
    plan,
    days,
    passengersArr,
    adminDiscount,
    adminAdjustment,
  });

  plan.highlights = [];

  if (highlightStyle.includes("client")) {
    // ------------------------------------------------------------------------------------------------ //
    // health_care
    // ------------------------------------------------------------------------------------------------ //

    let highlight = plan?.coverages?.find(
      (c) => c?.coverageKey === "health_care",
    );
    if (highlight) {
      plan.highlights.push({
        icon: <MdOutlineMedicalServices />,
        title: translateX(highlight.coverageKey),
        description: financialService.formatMoney({
          value: highlight?.amount || 0,
          fix: 2,
          localeSymbol: highlight?.currency || "brl",
        }),
      });
    }

    // ------------------------------------------------------------------------------------------------ //
    // luggage_loss
    // ------------------------------------------------------------------------------------------------ //
    highlight = plan?.coverages?.find((c) => c?.coverageKey === "luggage_loss");
    if (highlight) {
      plan.highlights.push({
        icon: <MdOutlineLuggage />,
        title: translateX(highlight.coverageKey),
        description: financialService.formatMoney({
          value: highlight?.amount || 0,
          fix: 2,
          localeSymbol: highlight?.currency || "brl",
        }),
      });
    }
  }

  const _p = plan.summary.parameters;
  const rdPrice = plan.summary.resellerDefaultPrice;
  const rg1Price = plan.summary.resellerGrievance1Price;
  const rg2Price = plan.summary.resellerGrievance2Price;
  const rg3Price = plan.summary.resellerGrievance3Price;

  if (highlightStyle.includes("operator")) {
    // ------------------------------------------------------------------------------------------------ //
    // days
    // ------------------------------------------------------------------------------------------------ //
    plan.highlights.push({
      icon: <MdOutlineCalendarToday />,
      title: translateX("days"),
      description:
        days < _p.days ? `${days} (${translateX("min")} ${_p.days})` : days,
    });

    // ------------------------------------------------------------------------------------------------ //
    // grievance levels
    // ------------------------------------------------------------------------------------------------ //
    plan.highlights.push($buildGrievanceLevel(0, plan));

    if (["level1", "level2", "level3"].includes(plan.grievanceLevel)) {
      plan.highlights.push($buildGrievanceLevel(1, plan));
    }

    if (["level2", "level3"].includes(plan.grievanceLevel)) {
      plan.highlights.push($buildGrievanceLevel(2, plan));
    }

    if (["level3"].includes(plan.grievanceLevel)) {
      plan.highlights.push($buildGrievanceLevel(3, plan));
    }
  }

  return plan;

  ////

  function $buildGrievanceLevel(
    level,
    doc,
    summary = {},
    profile = "pricePerPassenger",
  ) {
    // let dailyPriceFrom;
    let dailyPriceTo;
    // let grievancePriceFrom;
    let grievancePriceTo;

    let minAge = 0;
    let icon = <MdOutlineElderly />;
    let title;

    switch (level) {
      case 1:
        minAge = _p.grievance1Ages[0];
        // dailyPriceFrom = rg1Price.dailyPrice;
        dailyPriceTo = rg1Price.netDailyPrice;
        // grievancePriceFrom = rg1Price.totalPrice;
        grievancePriceTo = rg1Price.netTotalPrice;

        title = `${minAge}+ ${translateX("years_old")} ${
          rg1Price.qty ? `(${rg1Price.qty})` : ""
        }`;
        break;

      case 2:
        minAge = _p.grievance2Ages[0];
        // dailyPriceFrom = rg2Price.dailyPrice;
        dailyPriceTo = rg2Price.netDailyPrice;
        // grievancePriceFrom = rg2Price.totalPrice;
        grievancePriceTo = rg2Price.netTotalPrice;

        title = `${minAge}+ ${translateX("years_old")} ${
          rg2Price.qty ? `(${rg2Price.qty})` : ""
        }`;
        break;

      case 3:
        minAge = _p.grievance3Ages[0];
        // dailyPriceFrom = rg3Price.dailyPrice;
        dailyPriceTo = rg3Price.netDailyPrice;
        // grievancePriceFrom = rg3Price.totalPrice;
        grievancePriceTo = rg3Price.netTotalPrice;

        title = `${minAge}+ ${translateX("years_old")} ${
          rg3Price.qty ? `(${rg3Price.qty})` : ""
        }`;
        break;

      default:
        icon = <MdPerson />;
        // dailyPriceFrom = rdPrice.dailyPrice;
        dailyPriceTo = rdPrice.netDailyPrice;
        // grievancePriceFrom = rdPrice.totalPrice;
        grievancePriceTo = rdPrice.netTotalPrice;

        title = `${translateX("price_per_traveler")} ${
          rdPrice.qty ? `(${rdPrice.qty})` : ""
        }`;
    }

    // const passengerPriceFrom = dailyPriceFrom * _p.days;
    const passengerPriceTo = dailyPriceTo * _p.days;
    // let priceFrom;
    let priceTo;

    switch (profile) {
      case "pricePerDay":
        // priceFrom = dailyPriceFrom;
        priceTo = dailyPriceTo;
        break;

      case "pricePerGrievance":
        // priceFrom = grievancePriceFrom;
        priceTo = grievancePriceTo;
        break;

      case "pricePerPassenger":
      default:
        // priceFrom = passengerPriceFrom;
        priceTo = passengerPriceTo;
        break;
    }

    // priceFrom = financialService.formatMoney({
    //   value: priceFrom || 0,
    //   fix: 2,
    //   localeSymbol: 'brl',
    // });

    priceTo = financialService.formatMoney({
      value: priceTo || 0,
      fix: 2,
      localeSymbol: "brl",
    });

    return {
      icon,
      title,
      description: (
        // <span>
        //   <s>{priceFrom}</s> | {priceTo}
        // </span>
        <span>{priceTo}</span>
      ),
    };
  }
};

appmultiService.calculatePlanValues_OLD = ({
  plan,
  days,
  passengersArr,
  adminDiscount = {
    accountId: null,
    type: "value",
    percentage: 0,
    value: 0,
  },
  adminAdjustment = {
    accountId: null,
    type: "value",
    percentage: 0,
    value: 0,
  },
}) => {
  const gLevels = appmultiService.buildLevels(plan);

  // _calcParameters
  const _p = {
    currency: plan?.currency || "brl",
    dailyPrice: plan?.dailyPrice || 0, // in dollar price day
    price: plan?.price || 0, // in dollar package price or daily price
    priceType: plan?.priceType || "daily",
    days: days < plan?.minDays ? plan?.minDays : days,
    exchangeRate: plan?.__operator?.parameters?.__exchangeRate || 1,
    grievance1: plan?.grievanceAdditionAge1 || 0, // %
    grievance2: plan?.grievanceAdditionAge2 || 0, // %
    grievance3: plan?.grievanceAdditionAge3 || 0, // %
    markdown: plan?.markdown || 0, // %
    discount: plan?.discount || 0, // %
    minDays: plan?.minDays || 1,
    maxDays: plan?.maxDays || 365,
    minAge: plan?.minAge || 0,
    maxAge: plan?.maxAge || 100,
    grievanceLevel: gLevels.level,
    grievance1Ages: gLevels?.grievance1Ages,
    grievance2Ages: gLevels?.grievance2Ages,
    grievance3Ages: gLevels?.grievance3Ages,
  };

  // U$ 3,35*33 dias = U$ 110,55 ( adicional de idade ) U$ 221,10*R$ 4,96 = R$ 1.096,66

  // *****************************************************************************************//
  // PROVIDER
  // *****************************************************************************************//

  // CALCULATE PROVIDER DEFAULT PRICE
  const pdP =
    _p.priceType === "package"
      ? _p.price || _p.dailyPrice
      : (_p.price || _p.dailyPrice) * _p.days;

  const pdUSD = financialService.fix(pdP, 2);
  const pdTotalBRL = financialService.fix(pdUSD * _p.exchangeRate, 2);
  const pdDailyBRL = financialService.fix(pdTotalBRL / _p.days, 2); // converted daily price in real
  const pdNetTotalBRL = financialService.discount(pdTotalBRL, _p.discount, 2);
  const pdNetDailyBRL = financialService.fix(pdNetTotalBRL / _p.days, 2);
  const pdPrice = {
    dailyPrice: pdDailyBRL,
    totalPrice: pdTotalBRL,
    netDailyPrice: pdNetDailyBRL,
    netTotalPrice: pdNetTotalBRL,
    qty: 0,
    fullDailyPrice: 0,
    fullTotalPrice: 0,
    fullNetDailyPrice: 0,
    fullNetTotalPrice: 0,
  };

  // CALCULATE PROVIDER GRIEVANCE 1
  const pg1USD = financialService.markup(pdUSD, _p.grievance1, 2);
  const pg1BRL = financialService.fix(pg1USD * _p.exchangeRate, 2);
  const pg1DailyBRL = financialService.fix(pg1BRL / _p.days, 2); // converted daily price in real
  const pg1NetBRL = financialService.discount(pg1BRL, _p.discount, 2);
  const pg1NetDailyBRL = financialService.fix(pg1NetBRL / _p.days, 2);
  const pg1Price =
    _p.grievanceLevel > 0
      ? {
          dailyPrice: pg1DailyBRL,
          totalPrice: pg1BRL,
          netDailyPrice: pg1NetDailyBRL,
          netTotalPrice: pg1NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE PROVIDER GRIEVANCE 2
  const pg2USD = financialService.markup(pdUSD, _p.grievance2, 2);
  const pg2BRL = financialService.fix(pg2USD * _p.exchangeRate, 2);
  const pg2DailyBRL = financialService.fix(pg2BRL / _p.days, 2); // converted daily price in real
  const pg2NetBRL = financialService.discount(pg2BRL, _p.discount, 2);
  const pg2NetDailyBRL = financialService.fix(pg2NetBRL / _p.days, 2);
  const pg2Price =
    _p.grievanceLevel > 1
      ? {
          dailyPrice: pg2DailyBRL,
          totalPrice: pg2BRL,
          netDailyPrice: pg2NetDailyBRL,
          netTotalPrice: pg2NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE PROVIDER GRIEVANCE 3
  const pg3USD = financialService.markup(pdUSD, _p.grievance3, 2);
  const pg3BRL = financialService.fix(pg3USD * _p.exchangeRate, 2);
  const pg3DailyBRL = financialService.fix(pg3BRL / _p.days, 2); // converted daily price in real
  const pg3NetBRL = financialService.discount(pg3BRL, _p.discount, 2);
  const pg3NetDailyBRL = financialService.fix(pg3NetBRL / _p.days, 2);
  const pg3Price =
    _p.grievanceLevel > 2
      ? {
          dailyPrice: pg3DailyBRL,
          totalPrice: pg3BRL,
          netDailyPrice: pg3NetDailyBRL,
          netTotalPrice: pg3NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // *****************************************************************************************//
  // RESELLER
  // *****************************************************************************************//

  // CALCULATE RESELLER DEFAULT PRICE
  const rdTotalBRL = financialService.markdownReverse(
    pdTotalBRL,
    _p.markdown,
    2,
  );
  const rdDailyBRL = financialService.fix(rdTotalBRL / _p.days, 2); // converted daily price in real
  const rdNetTotalBRL = financialService.discount(rdTotalBRL, _p.discount, 2);
  const rdNetDailyBRL = financialService.fix(rdNetTotalBRL / _p.days, 2);
  const rdPrice = {
    dailyPrice: rdDailyBRL,
    totalPrice: rdTotalBRL,
    netDailyPrice: rdNetDailyBRL,
    netTotalPrice: rdNetTotalBRL,
    qty: 0,
    fullDailyPrice: 0,
    fullTotalPrice: 0,
    fullNetDailyPrice: 0,
    fullNetTotalPrice: 0,
  };

  // CALCULATE RESELLER GRIEVANCE 1
  const rg1BRL = financialService.markdownReverse(pg1BRL, _p.markdown, 2);
  const rg1DailyBRL = financialService.fix(rg1BRL / _p.days, 2); // converted daily price in real
  const rg1NetBRL = financialService.discount(rg1BRL, _p.discount, 2);
  const rg1NetDailyBRL = financialService.fix(rg1NetBRL / _p.days, 2);
  const rg1Price =
    _p.grievanceLevel > 0
      ? {
          dailyPrice: rg1DailyBRL,
          totalPrice: rg1BRL,
          netDailyPrice: rg1NetDailyBRL,
          netTotalPrice: rg1NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE RESELLER GRIEVANCE 2
  const rg2BRL = financialService.markdownReverse(pg2BRL, _p.markdown, 2);
  const rg2DailyBRL = financialService.fix(rg2BRL / _p.days, 2); // converted daily price in real
  const rg2NetBRL = financialService.discount(rg2BRL, _p.discount, 2);
  const rg2NetDailyBRL = financialService.fix(rg2NetBRL / _p.days, 2);
  const rg2Price =
    _p.grievanceLevel > 1
      ? {
          dailyPrice: rg2DailyBRL,
          totalPrice: rg2BRL,
          netDailyPrice: rg2NetDailyBRL,
          netTotalPrice: rg2NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE RESELLER GRIEVANCE 3
  const rg3BRL = financialService.markdownReverse(pg3BRL, _p.markdown, 3);
  const rg3DailyBRL = financialService.fix(rg3BRL / _p.days, 2); // converted daily price in real
  const rg3NetBRL = financialService.discount(rg3BRL, _p.discount, 2);
  const rg3NetDailyBRL = financialService.fix(rg3NetBRL / _p.days, 2);
  const rg3Price =
    _p.grievanceLevel > 2
      ? {
          dailyPrice: rg3DailyBRL,
          totalPrice: rg3BRL,
          netDailyPrice: rg3NetDailyBRL,
          netTotalPrice: rg3NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE THE GRIEVANCE BY TRAVELLERS
  passengersArr.map((p) => {
    const passengerAge = typeof p === "object" ? p.age : p;

    let travelLevel = appmultiService.getTravelGrievanceLevel(
      plan,
      passengerAge,
    );

    switch (travelLevel) {
      case 3:
        pg3Price.qty++;
        rg3Price.qty++;
        break;

      case 2:
        pg2Price.qty++;
        rg2Price.qty++;
        break;

      case 1:
        pg1Price.qty++;
        rg1Price.qty++;
        break;

      case 0:
      default:
        pdPrice.qty++;
        rdPrice.qty++;
        break;
    }

    return passengerAge;
  });

  if (pdPrice?.qty) {
    const c = pdPrice;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg1Price?.qty) {
    const c = pg1Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg2Price?.qty) {
    const c = pg2Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg3Price?.qty) {
    const c = pg3Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rdPrice?.qty) {
    const c = rdPrice;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg1Price?.qty) {
    const c = rg1Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg2Price?.qty) {
    const c = rg2Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg3Price?.qty) {
    const c = rg3Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  const providerGrossPrice =
    (pdPrice?.fullTotalPrice || 0) +
    (pg1Price?.fullTotalPrice || 0) +
    (pg2Price?.fullTotalPrice || 0) +
    (pg3Price?.fullTotalPrice || 0);

  const providerNetPrice =
    (pdPrice?.fullNetTotalPrice || 0) +
    (pg1Price?.fullNetTotalPrice || 0) +
    (pg2Price?.fullNetTotalPrice || 0) +
    (pg3Price?.fullNetTotalPrice || 0);

  const resellerGrossPrice =
    (rdPrice?.fullTotalPrice || 0) +
    (rg1Price?.fullTotalPrice || 0) +
    (rg2Price?.fullTotalPrice || 0) +
    (rg3Price?.fullTotalPrice || 0);

  const resellerNetPrice =
    (rdPrice?.fullNetTotalPrice || 0) +
    (rg1Price?.fullNetTotalPrice || 0) +
    (rg2Price?.fullNetTotalPrice || 0) +
    (rg3Price?.fullNetTotalPrice || 0);

  const resellerGrossProfit = resellerNetPrice - providerNetPrice;

  const couponDiscount = {
    _id: null,
    type: "percentagem",
    discount: 0,
    discountValue: 0,
  };

  const netPriceWithCouponDiscount =
    resellerNetPrice - (couponDiscount?.discountValue || 0);

  const totalToPay =
    netPriceWithCouponDiscount -
    (adminDiscount?.value || 0) +
    (adminAdjustment?.value || 0);

  const resellerNetProfit = totalToPay - providerNetPrice;

  return {
    parameters: _p,
    providerDefaultPrice: pdPrice,
    providerGrievance1Price: pg1Price,
    providerGrievance2Price: pg2Price,
    providerGrievance3Price: pg3Price,

    resellerDefaultPrice: rdPrice,
    resellerGrievance1Price: rg1Price,
    resellerGrievance2Price: rg2Price,
    resellerGrievance3Price: rg3Price,

    total: {
      providerGrossPrice: financialService.fix(providerGrossPrice, 2),
      providerNetPrice: financialService.fix(providerNetPrice, 2),
      resellerGrossPrice: financialService.fix(resellerGrossPrice, 2),
      resellerNetPrice: financialService.fix(resellerNetPrice, 2),
      resellerGrossProfit: financialService.fix(resellerGrossProfit, 2),
      couponDiscount,
      netPriceWithCouponDiscount: financialService.fix(
        netPriceWithCouponDiscount,
        2,
      ),
      adminDiscount,
      adminAdjustment,
      totalToPay: financialService.fix(totalToPay, 2),
      resellerNetProfit: financialService.fix(resellerNetProfit, 2),
    },
  };
};

appmultiService.calculatePlanValues = ({
  plan,
  days,
  passengersArr,
  adminDiscount = plan?.summary?.total?.adminDiscount || {
    accountId: null,
    type: "value",
    percentage: 0,
    value: 0,
  },
  adminAdjustment = plan?.summary?.total?.adminAdjustment || {
    accountId: null,
    type: "value",
    percentage: 0,
    value: 0,
  },
  paymentMethod,
  creditCardInstallment,
}) => {
  const gLevels = appmultiService.buildLevels(plan);

  // _calcParameters
  const _p = {
    currency: plan?.currency || "brl",
    dailyPrice: plan?.dailyPrice || 0, // in dollar price day
    price: plan?.price || 0, // in dollar package price or daily price
    priceType: plan?.priceType || "daily",
    days: days < plan?.minDays ? plan?.minDays : days,
    exchangeRate: plan?.__operator?.parameters?.__exchangeRate || 1,
    grievance1: plan?.grievanceAdditionAge1 || 0, // %
    grievance2: plan?.grievanceAdditionAge2 || 0, // %
    grievance3: plan?.grievanceAdditionAge3 || 0, // %
    markdown: plan?.markdown || 0, // %
    discount: plan?.discount || 0, // %
    minDays: plan?.minDays || 1,
    maxDays: plan?.maxDays || 365,
    minAge: plan?.minAge || 0,
    maxAge: plan?.maxAge || 100,
    grievanceLevel: gLevels.level,
    grievance1Ages: gLevels?.grievance1Ages,
    grievance2Ages: gLevels?.grievance2Ages,
    grievance3Ages: gLevels?.grievance3Ages,
  };

  // U$ 3,35*33 dias = U$ 110,55 ( adicional de idade ) U$ 221,10*R$ 4,96 = R$ 1.096,66

  // *****************************************************************************************//
  // PROVIDER
  // *****************************************************************************************//

  // CALCULATE PROVIDER DEFAULT PRICE
  const pdP = ["package", "table"].includes(_p.priceType)
    ? _p.price || _p.dailyPrice
    : (_p.price || _p.dailyPrice) * _p.days;

  const pdUSD = financialService.fix(pdP, 2);
  const pdTotalBRL = financialService.fix(pdUSD * _p.exchangeRate, 2);
  const pdDailyBRL = financialService.fix(pdTotalBRL / _p.days, 2); // converted daily price in real
  const pdNetTotalBRL = financialService.discount(pdTotalBRL, _p.discount, 2);
  const pdNetDailyBRL = financialService.fix(pdNetTotalBRL / _p.days, 2);
  const pdPrice = {
    dailyPrice: pdDailyBRL,
    totalPrice: pdTotalBRL,
    netDailyPrice: pdNetDailyBRL,
    netTotalPrice: pdNetTotalBRL,
    qty: 0,
    fullDailyPrice: 0,
    fullTotalPrice: 0,
    fullNetDailyPrice: 0,
    fullNetTotalPrice: 0,
  };

  // CALCULATE PROVIDER GRIEVANCE 1
  const pg1USD = financialService.markup(pdUSD, _p.grievance1, 2);
  const pg1BRL = financialService.fix(pg1USD * _p.exchangeRate, 2);
  const pg1DailyBRL = financialService.fix(pg1BRL / _p.days, 2); // converted daily price in real
  const pg1NetBRL = financialService.discount(pg1BRL, _p.discount, 2);
  const pg1NetDailyBRL = financialService.fix(pg1NetBRL / _p.days, 2);
  const pg1Price =
    _p.grievanceLevel > 0
      ? {
          dailyPrice: pg1DailyBRL,
          totalPrice: pg1BRL,
          netDailyPrice: pg1NetDailyBRL,
          netTotalPrice: pg1NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE PROVIDER GRIEVANCE 2
  const pg2USD = financialService.markup(pdUSD, _p.grievance2, 2);
  const pg2BRL = financialService.fix(pg2USD * _p.exchangeRate, 2);
  const pg2DailyBRL = financialService.fix(pg2BRL / _p.days, 2); // converted daily price in real
  const pg2NetBRL = financialService.discount(pg2BRL, _p.discount, 2);
  const pg2NetDailyBRL = financialService.fix(pg2NetBRL / _p.days, 2);
  const pg2Price =
    _p.grievanceLevel > 1
      ? {
          dailyPrice: pg2DailyBRL,
          totalPrice: pg2BRL,
          netDailyPrice: pg2NetDailyBRL,
          netTotalPrice: pg2NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE PROVIDER GRIEVANCE 3
  const pg3USD = financialService.markup(pdUSD, _p.grievance3, 2);
  const pg3BRL = financialService.fix(pg3USD * _p.exchangeRate, 2);
  const pg3DailyBRL = financialService.fix(pg3BRL / _p.days, 2); // converted daily price in real
  const pg3NetBRL = financialService.discount(pg3BRL, _p.discount, 2);
  const pg3NetDailyBRL = financialService.fix(pg3NetBRL / _p.days, 2);
  const pg3Price =
    _p.grievanceLevel > 2
      ? {
          dailyPrice: pg3DailyBRL,
          totalPrice: pg3BRL,
          netDailyPrice: pg3NetDailyBRL,
          netTotalPrice: pg3NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // *****************************************************************************************//
  // RESELLER
  // *****************************************************************************************//

  // CALCULATE RESELLER DEFAULT PRICE
  const rdTotalBRL = financialService.markdownReverse(
    pdTotalBRL,
    _p.markdown,
    2,
  );
  const rdDailyBRL = financialService.fix(rdTotalBRL / _p.days, 2); // converted daily price in real
  const rdNetTotalBRL = financialService.discount(rdTotalBRL, _p.discount, 2);
  const rdNetDailyBRL = financialService.fix(rdNetTotalBRL / _p.days, 2);
  const rdPrice = {
    dailyPrice: rdDailyBRL,
    totalPrice: rdTotalBRL,
    netDailyPrice: rdNetDailyBRL,
    netTotalPrice: rdNetTotalBRL,
    qty: 0,
    fullDailyPrice: 0,
    fullTotalPrice: 0,
    fullNetDailyPrice: 0,
    fullNetTotalPrice: 0,
  };

  // CALCULATE RESELLER GRIEVANCE 1
  const rg1BRL = financialService.markdownReverse(pg1BRL, _p.markdown, 2);
  const rg1DailyBRL = financialService.fix(rg1BRL / _p.days, 2); // converted daily price in real
  const rg1NetBRL = financialService.discount(rg1BRL, _p.discount, 2);
  const rg1NetDailyBRL = financialService.fix(rg1NetBRL / _p.days, 2);
  const rg1Price =
    _p.grievanceLevel > 0
      ? {
          dailyPrice: rg1DailyBRL,
          totalPrice: rg1BRL,
          netDailyPrice: rg1NetDailyBRL,
          netTotalPrice: rg1NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE RESELLER GRIEVANCE 2
  const rg2BRL = financialService.markdownReverse(pg2BRL, _p.markdown, 2);
  const rg2DailyBRL = financialService.fix(rg2BRL / _p.days, 2); // converted daily price in real
  const rg2NetBRL = financialService.discount(rg2BRL, _p.discount, 2);
  const rg2NetDailyBRL = financialService.fix(rg2NetBRL / _p.days, 2);
  const rg2Price =
    _p.grievanceLevel > 1
      ? {
          dailyPrice: rg2DailyBRL,
          totalPrice: rg2BRL,
          netDailyPrice: rg2NetDailyBRL,
          netTotalPrice: rg2NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE RESELLER GRIEVANCE 3
  const rg3BRL = financialService.markdownReverse(pg3BRL, _p.markdown, 3);
  const rg3DailyBRL = financialService.fix(rg3BRL / _p.days, 2); // converted daily price in real
  const rg3NetBRL = financialService.discount(rg3BRL, _p.discount, 2);
  const rg3NetDailyBRL = financialService.fix(rg3NetBRL / _p.days, 2);
  const rg3Price =
    _p.grievanceLevel > 2
      ? {
          dailyPrice: rg3DailyBRL,
          totalPrice: rg3BRL,
          netDailyPrice: rg3NetDailyBRL,
          netTotalPrice: rg3NetBRL,
          qty: 0,
          fullDailyPrice: 0,
          fullTotalPrice: 0,
          fullNetDailyPrice: 0,
          fullNetTotalPrice: 0,
        }
      : null;

  // CALCULATE THE GRIEVANCE BY TRAVELLERS
  passengersArr.map((p) => {
    const passengerAge = typeof p === "object" ? p.age : p;

    let travelLevel = appmultiService.getTravelGrievanceLevel(
      plan,
      passengerAge,
    );

    switch (travelLevel) {
      case 3:
        pg3Price.qty++;
        rg3Price.qty++;
        break;

      case 2:
        pg2Price.qty++;
        rg2Price.qty++;
        break;

      case 1:
        pg1Price.qty++;
        rg1Price.qty++;
        break;

      case 0:
      default:
        pdPrice.qty++;
        rdPrice.qty++;
        break;
    }

    return passengerAge;
  });

  if (pdPrice?.qty) {
    const c = pdPrice;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg1Price?.qty) {
    const c = pg1Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg2Price?.qty) {
    const c = pg2Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (pg3Price?.qty) {
    const c = pg3Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rdPrice?.qty) {
    const c = rdPrice;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg1Price?.qty) {
    const c = rg1Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg2Price?.qty) {
    const c = rg2Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  if (rg3Price?.qty) {
    const c = rg3Price;
    const qty = c.qty;
    c.fullDailyPrice = financialService.fix(c.dailyPrice * qty, 2);
    c.fullTotalPrice = financialService.fix(c.totalPrice * qty, 2);
    c.fullNetDailyPrice = financialService.fix(c.netDailyPrice * qty, 2);
    c.fullNetTotalPrice = financialService.fix(c.netTotalPrice * qty, 2);
  }

  const providerGrossPrice =
    (pdPrice?.fullTotalPrice || 0) +
    (pg1Price?.fullTotalPrice || 0) +
    (pg2Price?.fullTotalPrice || 0) +
    (pg3Price?.fullTotalPrice || 0);

  const providerNetPrice =
    (pdPrice?.fullNetTotalPrice || 0) +
    (pg1Price?.fullNetTotalPrice || 0) +
    (pg2Price?.fullNetTotalPrice || 0) +
    (pg3Price?.fullNetTotalPrice || 0);

  const resellerGrossPrice =
    (rdPrice?.fullTotalPrice || 0) +
    (rg1Price?.fullTotalPrice || 0) +
    (rg2Price?.fullTotalPrice || 0) +
    (rg3Price?.fullTotalPrice || 0);

  // RESELLER NET PRICE
  const resellerNetPrice =
    (rdPrice?.fullNetTotalPrice || 0) +
    (rg1Price?.fullNetTotalPrice || 0) +
    (rg2Price?.fullNetTotalPrice || 0) +
    (rg3Price?.fullNetTotalPrice || 0);

  // ADJUSTED PRICE
  const adjustedPrice =
    resellerNetPrice -
    (adminDiscount?.value || 0) +
    (adminAdjustment?.value || 0);

  // COUPON DISCOUNT PRICE
  const couponDiscount = {
    _id: null,
    type: "percentagem",
    discount: 0,
    discountValue: 0,
  };

  const totalWithCoupomPrice =
    adjustedPrice - (couponDiscount?.discountValue || 0);

  // PAYMENT DISCOUNT AND METHOD
  let paymentDiscount = 0; // aqui deverá vir o desconto (%) de acordo com a forma de pagamento (ex: 5% de desconto no PIX ou BOLETO)
  let paymentDiscountValue = 0; // aqui deverá vir o desconto (R$) de acordo com a forma de pagamento (ex: 5% de desconto no PIX ou BOLETO)
  let paymentInstallmentValue = totalWithCoupomPrice;
  let paymentInstallment = creditCardInstallment
    ? parseInt(returnOnlyNumbers(creditCardInstallment))
    : 0;

  switch (paymentMethod) {
    case "slip_pix":
    case "pix":
      paymentInstallment = 1;
      paymentDiscount = 5;
      paymentDiscountValue = financialService.percentageToValue(
        totalWithCoupomPrice,
        paymentDiscount,
        2,
      );
      break;

    case "credit_card":
      paymentInstallmentValue = financialService.fix(
        totalWithCoupomPrice / paymentInstallment,
        2,
      );
      break;

    default:
      break;
  }

  // TOTAL TO PAY
  const totalToPay = totalWithCoupomPrice - paymentDiscountValue;

  // PROFITS
  const resellerGrossProfit = resellerNetPrice - providerNetPrice;
  const resellerNetProfit = totalToPay - providerNetPrice;

  return {
    parameters: _p,
    providerDefaultPrice: pdPrice,
    providerGrievance1Price: pg1Price,
    providerGrievance2Price: pg2Price,
    providerGrievance3Price: pg3Price,

    resellerDefaultPrice: rdPrice,
    resellerGrievance1Price: rg1Price,
    resellerGrievance2Price: rg2Price,
    resellerGrievance3Price: rg3Price,

    total: {
      providerGrossPrice: financialService.fix(providerGrossPrice, 2),
      providerNetPrice: financialService.fix(providerNetPrice, 2),
      resellerGrossPrice: financialService.fix(resellerGrossPrice, 2),
      resellerGrossProfit: financialService.fix(resellerGrossProfit, 2),

      // BASE PRICE
      resellerNetPrice: financialService.fix(resellerNetPrice, 2),

      // TOTAL ADJUSTED PRICE
      adminDiscount,
      adminAdjustment,
      totalAdjusted: financialService.fix(adjustedPrice, 2),

      // TOTAL WITH COUPOM DISCOUNT
      couponDiscount,
      netPriceWithCouponDiscount: financialService.fix(totalWithCoupomPrice, 2),

      // TOTAL WITH PAYMENT DISCOUNT
      paymentDiscount,
      paymentDiscountValue,
      totalWithPaymentDiscount: financialService.fix(totalToPay, 2),

      // TOTAL TO PAY
      totalToPay: financialService.fix(totalToPay, 2),
      resellerNetProfit: financialService.fix(resellerNetProfit, 2),

      // PAYMENT METHOD
      paymentMethod,
      paymentInstallmentTitle:
        paymentMethod === "credit_card" ? creditCardInstallment : "1X",
      paymentInstallment,
      paymentInstallmentValue,
    },
  };
};

appmultiService.sorterPlans = (plans, sorterObj) => {
  const operator = sorterObj?.operator || null;
  const profile = sorterObj?.profile || null;
  const classification = sorterObj?.classification || "best_seller";

  let plansSorted = clone(plans);
  let idx;

  // *****************************************************************
  // operator
  // *****************************************************************
  if (operator) {
    plansSorted = plansSorted?.filter((p) => p?.__operator?._id === operator);
  }

  // *****************************************************************
  // profile
  // *****************************************************************
  if (profile) {
    plansSorted = plansSorted?.filter((p) =>
      p.travelProfiles?.includes(profile),
    );
  }

  // *****************************************************************
  // classification
  // *****************************************************************
  plansSorted?.map((p) => {
    p.bestSeller = false;
    p.cheaper = false;
    p.moreExpansive = false;
    return p;
  });

  // best seller
  const rankingOrders = plansSorted?.sort((a, b) =>
    sort(b, a, "rankingOrders"),
  )?.[0];

  const bestSellerId =
    rankingOrders?.rankingOrders > 0 ? rankingOrders._id : undefined;

  if (bestSellerId) {
    idx = plansSorted?.findIndex((p) => p._id === bestSellerId);
    if (idx > -1) plansSorted[idx].bestSeller = true;
  }

  // ----------- cheaper and more expansive

  const allValuesAreEqual = plansSorted?.every((item, index) => {
    if (index === 0) return true;
    return (
      item?.summary?.total?.totalToPay ===
      plansSorted?.[0]?.summary?.total?.totalToPay
    );
  });

  // cheaper
  const cheaperId = plansSorted?.sort(
    (a, b) => a?.summary?.total?.totalToPay - b?.summary?.total?.totalToPay,
  )?.[0]?._id;
  if (cheaperId && plansSorted.length > 1 && !allValuesAreEqual) {
    idx = plansSorted?.findIndex((p) => p._id === cheaperId);
    if (idx > -1) plansSorted[idx].cheaper = true;
  }

  // more expansive
  const moreExpansiveId = plansSorted?.sort(
    (a, b) => b?.summary?.total?.totalToPay - a?.summary?.total?.totalToPay,
  )?.[0]?._id;
  if (moreExpansiveId && plansSorted.length > 1 && !allValuesAreEqual) {
    idx = plansSorted?.findIndex((p) => p._id === moreExpansiveId);
    if (idx > -1) plansSorted[idx].moreExpansive = true;
  }

  switch (classification) {
    case "best_seller": {
      plansSorted = plansSorted
        ?.sort(
          (a, b) => a.summary.total.totalToPay - b.summary.total.totalToPay,
        )
        ?.sort((a, b) => b.bestSeller - a.bestSeller);
      break;
    }

    case "cheaper": {
      plansSorted = plansSorted
        ?.sort(
          (a, b) => a.summary.total.totalToPay - b.summary.total.totalToPay,
        )
        ?.sort((a, b) => sort(a, b, "moreExpansive"));
      break;
    }

    case "more_expansive": {
      plansSorted = plansSorted
        .sort((a, b) => b.summary.total.totalToPay - a.summary.total.totalToPay)
        .sort((a, b) => sort(a, b, "cheaper"));
      break;
    }

    default:
  }

  return plansSorted;
};

appmultiService.getOperatorsFromPlans = (plans) => {
  const arr = plans?.map((p) => {
    return {
      value: p?.__operator?._id,
      text: p?.__operator?.name,
      label: p?.__operator?.name,
      tooltipText: p?.__operator?.name,
      disabled: false,
    };
  });

  const operatorsArr = arr.filter(
    (obj, index, array) =>
      array.findIndex((item) => item.value === obj.value) === index,
  );

  return operatorsArr.sort((a, b) => sort(a, b, "text"));
};

appmultiService.getTravelGrievanceLevel = (plan, age) => {
  const gLevels = appmultiService.buildLevels(plan);

  if (gLevels.level > 0) {
    if (
      gLevels.grievance3Ages &&
      age >= gLevels.grievance3Ages[0] &&
      age <= gLevels.grievance3Ages[1]
    ) {
      return 3;
    }
    if (
      gLevels.grievance2Ages &&
      age >= gLevels.grievance2Ages[0] &&
      age <= gLevels.grievance2Ages[1]
    ) {
      return 2;
    }
    if (
      gLevels.grievance1Ages &&
      age >= gLevels.grievance1Ages[0] &&
      age <= gLevels.grievance1Ages[1]
    ) {
      return 1;
    }
  }

  return 0;
};

appmultiService.buildLevels = (plan) => {
  let enabledLevels;

  switch (plan?.grievanceLevel) {
    case "level1":
      enabledLevels = 1;
      break;

    case "level2":
      enabledLevels = 2;
      break;

    case "level3":
      enabledLevels = 3;
      break;

    default:
      enabledLevels = 0;
      break;
  }

  const par = {};

  if (enabledLevels > 0 && plan?.grievanceMinAge1) {
    par.grievance1Ages = [
      plan.grievanceMinAge1,
      plan?.grievanceMaxAge1 || par.maxAge,
    ];
  } else {
    par.grievance1Ages = null;
  }

  if (enabledLevels > 1 && plan?.grievanceMinAge2) {
    par.grievance2Ages = [
      plan.grievanceMinAge2,
      plan?.grievanceMaxAge2 || par.maxAge,
    ];
  } else {
    par.grievance2Ages = null;
  }

  if (enabledLevels > 2 && plan?.grievanceMinAge3) {
    par.grievance3Ages = [
      plan.grievanceMinAge3,
      plan?.grievanceMaxAge3 || par.maxAge,
    ];
  } else {
    par.grievance3Ages = null;
  }

  return {
    level: enabledLevels,
    ...par,
  };
};

appmultiService.getCoverageValue = (record, returnAsComponent = false) => {
  switch (record?.type) {
    case "money": {
      const value = financialService.formatMoney(
        record?.amount,
        2,
        record?.currency,
        true,
      );
      return returnAsComponent ? <div>{value}</div> : value;
    }

    case "switch": {
      const value = !!record?.included;
      return returnAsComponent ? (
        <Switch
          checked={value}
          checkedChildren={translateX("yes")}
          unCheckedChildren={translateX("no")}
          disabled={true}
        />
      ) : (
        value
      );
    }

    case "text":
    default: {
      const value = record?.text;
      return returnAsComponent ? <div>{value}</div> : value;
    }
  }
};

appmultiService.generateOrderFromQuotation_OLD = async (
  filter,
  plan,
  travellers,
  buyer,
  emergencyContacts,
  billingType,
) => {
  const f = filter;
  const p = plan;
  const _s = plan?.summary || {};
  const _p = plan?.summary?.parameters || {};

  const b = buyer;

  const t = travellers || [];
  const buyerAddress = entityService.getAddress(buyer);
  const body = {
    destiny: f.destiny,
    departure: dayjs(f.departure),
    arrival: dayjs(f.arrival),
    days: f.days,
    minDays: _p.days,
    buyerId: b._id,
    buyerAddress: { ...buyerAddress },
    planId: p._id,
    operatorId: p.operatorId,

    parametersInOrderDate: {
      currency: _p.currency,
      dailyPrice: _p.dailyPrice, // in dollar
      exchangeRate: _p.exchangeRate,
      grievance1: _p.grievance1,
      grievance2: _p.grievance2,
      grievance3: _p.grievance3,
      markdown: _p.markdown,
      discount: _p.discount,
      minDays: _p.minDays,
      maxDays: _p.maxDays,
      minAge: _p.minAge,
      maxAge: _p.maxAge,
      grievanceLevel: _p.grievanceLevel,
      grievance1Ages: _p.grievance1Ages,
      grievance2Ages: _p.grievance2Ages,
      grievance3Ages: _p.grievance3Ages,
    },
    parametersInVoucherDate: {
      currency: _p.currency,
      dailyPrice: _p.dailyPrice, // in dollar
      exchangeRate: _p.exchangeRate,
      grievance1: _p.grievance1,
      grievance2: _p.grievance2,
      grievance3: _p.grievance3,
      markdown: _p.markdown,
      discount: _p.discount,
      minDays: _p.minDays,
      maxDays: _p.maxDays,
      minAge: _p.minAge,
      maxAge: _p.maxAge,
      grievanceLevel: _p.grievanceLevel,
      grievance1Ages: _p.grievance1Ages,
      grievance2Ages: _p.grievance2Ages,
      grievance3Ages: _p.grievance3Ages,
    },

    emergencyContacts,

    travellers: t.map((t) => {
      let providerPrice;
      let resellerPrice;
      let grievancePercentage;

      switch (t.grievanceLevel) {
        case 3:
          providerPrice = _s?.providerGrievance3Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance3Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge3;
          break;

        case 2:
          providerPrice = _s?.providerGrievance2Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance2Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge2;
          break;

        case 1:
          providerPrice = _s?.providerGrievance1Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance1Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge1;
          break;

        case 0:
        default:
          providerPrice = _s?.providerDefaultPrice?.netTotalPrice || 0;
          resellerPrice = _s?.resellerDefaultPrice?.netTotalPrice || 0;
          grievancePercentage = 0;
          break;
      }

      return {
        entityId: t.entityId,
        ageInTravelDate: t.age,
        grievanceLevel: t.grievanceLevel || 0,
        grievancePercentage,
        valuesInOrderDate: {
          providerPrice,
          resellerPrice,
        },
        valuesInVoucherDate: {
          providerPrice,
          resellerPrice,
        },
      };
    }),

    totalInOrderDate: _s.total,
    totalInVoucherDate: _s.total,

    // 1) 'waiting_for_payment:warning' -> aguardando pagamento
    // 2) 'waiting_for_voucher:processing' -> pago, aguardando geração do voucher
    // 3) 'done:success' -> voucher gerado (processo de venda concluído)
    // 4) 'cancelled:error' -> vencido/não pago
    status: "waiting_for_payment",
    // receivableId: null,
    voucherDate: null,
    // paymentUrl: `https://www.asaas.com/i/${+new Date()}`,

    saleOrigin: "online",
  };

  // POST ORDER
  let axiosOptions = { url: API_ORDERS_URL, data: body };

  let [order] = await axiosService.post(axiosOptions);

  // POST RECEIVABLE
  await receivableService.generateBillFromOrder(
    order?._id,
    buyer?._id,
    billingType,
    undefined,
  );

  if (!order) {
    appService.notification(
      "e",
      "order_generation_error_description",
      "order",
      null,
      5,
    );
  } else {
    appService.notification(
      "s",
      "order_generation_success_description",
      "order",
      null,
      5,
    );
  }

  orderService.sendEmail(order?._id, "created").then();
  return await orderService.getOrderById(order?._id);
};

appmultiService.generateOrderFromQuotation = async (
  filter,
  plan,
  travellers,
  buyer,
  emergencyContacts = [],
  billingType,
) => {
  const f = filter;
  const p = plan;
  const _s = plan?.summary || {};
  const _p = plan?.summary?.parameters || {};

  const b = buyer;
  const t = travellers || [];

  let _indice = `${b.name} | ${b.cpf} | ${b.email} | ${b.phone} `;

  t.forEach((x) => {
    _indice += `| ${x.name} `;
    if (x.cpf) _indice += `| ${x.cpf} `;
  });

  const buyerAddress = entityService.getAddress(b);

  const body = {
    _indice,

    destiny: f.destiny,
    departure: dayjs(f.departure),
    arrival: dayjs(f.arrival),
    days: f.days,
    minDays: _p.days,
    buyerId: b._id,
    buyerAddress: buyerAddress,
    planId: p._id,
    operatorId: p.operatorId,

    parametersInOrderDate: {
      currency: _p.currency,
      dailyPrice: _p.dailyPrice, // in dollar
      exchangeRate: _p.exchangeRate,
      grievance1: _p.grievance1,
      grievance2: _p.grievance2,
      grievance3: _p.grievance3,
      markdown: _p.markdown,
      discount: _p.discount,
      minDays: _p.minDays,
      maxDays: _p.maxDays,
      minAge: _p.minAge,
      maxAge: _p.maxAge,
      grievanceLevel: _p.grievanceLevel,
      grievance1Ages: _p.grievance1Ages,
      grievance2Ages: _p.grievance2Ages,
      grievance3Ages: _p.grievance3Ages,
    },
    parametersInVoucherDate: {
      currency: _p.currency,
      dailyPrice: _p.dailyPrice, // in dollar
      exchangeRate: _p.exchangeRate,
      grievance1: _p.grievance1,
      grievance2: _p.grievance2,
      grievance3: _p.grievance3,
      markdown: _p.markdown,
      discount: _p.discount,
      minDays: _p.minDays,
      maxDays: _p.maxDays,
      minAge: _p.minAge,
      maxAge: _p.maxAge,
      grievanceLevel: _p.grievanceLevel,
      grievance1Ages: _p.grievance1Ages,
      grievance2Ages: _p.grievance2Ages,
      grievance3Ages: _p.grievance3Ages,
    },

    emergencyContacts,

    travellers: t.map((t) => {
      let providerPrice;
      let resellerPrice;
      let grievancePercentage;

      switch (t.grievanceLevel) {
        case 3:
          providerPrice = _s?.providerGrievance3Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance3Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge3;
          break;

        case 2:
          providerPrice = _s?.providerGrievance2Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance2Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge2;
          break;

        case 1:
          providerPrice = _s?.providerGrievance1Price?.netTotalPrice || 0;
          resellerPrice = _s?.resellerGrievance1Price?.netTotalPrice || 0;
          grievancePercentage = p?.grievanceAdditionAge1;
          break;

        case 0:
        default:
          providerPrice = _s?.providerDefaultPrice?.netTotalPrice || 0;
          resellerPrice = _s?.resellerDefaultPrice?.netTotalPrice || 0;
          grievancePercentage = 0;
          break;
      }

      return {
        entityId: t.entityId,
        ageInTravelDate: t.age,
        grievanceLevel: t.grievanceLevel || 0,
        grievancePercentage,
        valuesInOrderDate: {
          providerPrice,
          resellerPrice,
        },
        valuesInVoucherDate: {
          providerPrice,
          resellerPrice,
        },
      };
    }),

    totalInOrderDate: _s.total,
    totalInVoucherDate: _s.total,

    // 1) 'waiting_for_payment:warning' -> aguardando pagamento
    // 2) 'waiting_for_voucher:processing' -> pago, aguardando geração do voucher
    // 3) 'done:success' -> voucher gerado (processo de venda concluído)
    // 4) 'cancelled:error' -> vencido/não pago
    status: "waiting_for_payment",
    // receivableId: null,
    voucherDate: null,
    // paymentUrl: `https://www.asaas.com/i/${+new Date()}`,

    saleOrigin: "online",
  };

  // POST ORDER
  let axiosOptions = { url: API_ORDERS_URL, data: body };
  let [order] = await axiosService.post(axiosOptions);

  // POST RECEIVABLE
  // const { selectedCompany } = store.getState().authSlice;
  // const bank = selectedCompany?.__mainBank;

  await receivableService.generateBillFromOrder(
    order?._id,
    buyer?._id,
    billingType,
    undefined,
  );

  if (!order) {
    appService.notification(
      "e",
      "order_generation_error_description",
      "order",
      null,
      5,
    );
  } else {
    appService.notification(
      "s",
      "order_generation_success_description",
      "order",
      null,
      5,
    );
  }

  orderService.sendEmail(order?._id, "created").then();
  return await orderService.getOrderById(order?._id);
};

export default appmultiService;
