import { strToNumber, translateX } from "../utils/helpers";

const financialService = {};

financialService.markup = (value = 0, markup = 0, fix = 0) => {
  if (isNaN(value) || isNaN(markup)) return 0;

  const v = Number(value);
  const m = Number(markup);

  if (!v) return 0;
  if (!m) return v;

  const result = v * (1 + markup / 100);
  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.markdownReverse = (cost = 0, markdown = 0, fix = 0) => {
  if (isNaN(cost) || isNaN(markdown)) return 0;

  const v = Number(cost);
  const m = Number(markdown);

  if (!v) return 0;
  if (!m) return v;

  const result = v / ((100 - markdown) / 100); // V / 0.40 (60% markdown)
  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.percentageCalculator = (
  value = 0,
  percentage = 0,
  fix = 0,
) => {
  if (isNaN(value) || isNaN(percentage)) return 0;

  const v = Number(value);
  const m = Number(percentage);

  if (!v) return 0;
  if (!m) return v;

  const result = v * (percentage / 100);
  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.valueToPercentage = (
  total = 0,
  value = 0,
  fix = 3,
  resultToStr = false,
) => {
  total = strToNumber(total);
  value = value ? strToNumber(value) : 0;

  if (isNaN(total) || isNaN(value)) return 0;

  const t = Number(total);
  const v = Number(value);

  if (!t) return $done(0);
  if (!v) return $done(0);

  const result = (v / t) * 100;

  return $done(result);

  function $done(result) {
    if (resultToStr) {
      return financialService.formatNumber(result, fix, null, true);
    } else {
      return fix ? Number(result.toFixed(fix)) : result;
    }
  }
};

financialService.percentageToValue = (
  total = 0,
  value = 0,
  fix = 2,
  resultToStr = false,
) => {
  total = strToNumber(total);
  value = value ? strToNumber(value) : 0;

  if (isNaN(total) || isNaN(value)) return 0;

  const t = Number(total);
  const v = Number(value);

  if (!t) return $done(0);
  if (!v) return $done(0);

  const result = (v / 100) * t;

  return $done(result);

  function $done(result) {
    if (resultToStr) {
      return financialService.formatNumber(result, fix, null, true);
    } else {
      return fix ? Number(result.toFixed(fix)) : result;
    }
  }
};

financialService.diffMarkup = (value1 = 0, value2 = 0, fix = 0) => {
  if (isNaN(value1) || isNaN(value2)) return 0;

  const v1 = Number(value1);
  const v2 = Number(value2);

  if (!v1) return 100;
  if (!v2) return -100;

  const result = 100 - (v2 / v1) * 100;

  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.diffMarkdown = (value1 = 0, value2 = 0, fix = 0) => {
  if (isNaN(value1) || isNaN(value2)) return 0;

  const v1 = Number(value1);
  const v2 = Number(value2);

  if (!v1) return 100;
  if (!v2) return -100;

  const result = -(((v1 - v2) / v1) * 100);

  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.discount = (
  value = 0,
  percentage = 0,
  fix = 0,
  returnAsString = false,
) => {
  if (isNaN(value) || isNaN(percentage)) return 0;

  const v = Number(value);
  const p = Number(percentage);

  if (!v) return 0;
  if (!p) return v;

  const result = v * (1 - percentage / 100);

  const resultFixed = fix ? Number(result.toFixed(fix)) : result;

  return !returnAsString
    ? resultFixed
    : financialService.formatMoney({
        value: resultFixed,
        localeSymbol: "brl",
        resultToStr: true,
      });
};

financialService.fix = (value = 0, fix = 0) => {
  if (isNaN(value)) return 0;

  const v = Number(value);

  if (!v) return 0;

  return fix ? Number(v.toFixed(fix)) : v;
};

financialService.ruleOfThree = (
  elementA = 0,
  resultA = 0,
  elementB = 0,
  fix = 0,
) => {
  if (isNaN(elementA) || isNaN(resultA) || isNaN(elementB)) return 0;

  const elA = Number(elementA);
  const rA = Number(resultA);
  const elB = Number(elementB);

  if (!elB) return 0;

  const result = (elB * rA) / elA;
  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.salePriceWithMarkup = (
  cost = 0,
  markup = 0,
  commission,
  fix = 0,
) => {
  if (isNaN(cost) || isNaN(markup) || isNaN(markup)) return 0;

  const v = Number(cost);
  const m = Number(markup);

  if (!v) return 0;
  if (!m) return v;

  const result = v * (1 + markup / 100);
  return fix ? Number(result.toFixed(fix)) : result;
};

financialService.formatMoney = (...args) => {
  let value =
    typeof args?.[0] === "object" ? args?.[0]?.value || 0 : args?.[0] || 0;
  let fix =
    typeof args?.[0] === "object" ? args?.[0]?.fix || 2 : args?.[1] || 2;
  let localeSymbol =
    typeof args?.[0] === "object"
      ? args?.[0]?.localeSymbol || null
      : args?.[2] || null;
  let resultToStr =
    typeof args?.[0] === "object"
      ? args?.[0]?.resultToStr || false
      : args?.[3] || false;

  value = value ? strToNumber(value) : 0;
  const v = !isNaN(value) ? Number(value) : 0;

  if (isNaN(fix)) fix = 0;

  // const locale = navigator.language || "pt-BR";
  const locale = "pt-BR";

  const options =
    typeof localeSymbol === "string" && localeSymbol !== ""
      ? { style: "currency", currency: localeSymbol.toUpperCase(), fix }
      : fix
      ? { fix }
      : undefined;

  if (options && fix > 0) options.minimumFractionDigits = fix;

  const resultValue = fix ? Number(v.toFixed(fix)) : v;
  const resultStr = resultValue.toLocaleString(locale, options);

  return resultToStr ? resultStr : resultValue;
};

financialService.formatNumber = (...args) => {
  let value =
    typeof args?.[0] === "object" ? args?.[0]?.value || 0 : args?.[0] || 0;
  let fix =
    typeof args?.[0] === "object" ? args?.[0]?.fix || 0 : args?.[1] || 0;
  let localeSymbol =
    typeof args?.[0] === "object"
      ? args?.[0]?.localeSymbol || null
      : args?.[2] || null;
  let resultToStr =
    typeof args?.[0] === "object"
      ? args?.[0]?.resultToStr || false
      : args?.[3] || false;

  value = value ? strToNumber(value) : 0;
  const v = !isNaN(value) ? Number(value) : 0;

  if (isNaN(fix)) fix = 0;

  const locale = navigator.language || "pt-BR";
  const options =
    typeof localeSymbol === "string"
      ? { style: "decimal", currency: localeSymbol.toUpperCase(), fix }
      : fix
      ? { fix }
      : undefined;

  if (options && fix > 0) options.minimumFractionDigits = fix;

  const resultValue = fix ? Number(v.toFixed(fix)) : v;
  const resultStr = resultValue.toLocaleString(locale, options);

  return resultToStr ? resultStr : resultValue;
};

financialService.getCurrencySymbol = (currency) => {
  return "US$";
};

financialService.valueOrPercentageOptions = () => {
  return [
    {
      value: "value",
      text: translateX("real"),
      label: translateX("real_symbol"),
      disabled: false,
    },
    {
      value: "percentage",
      text: translateX("euro"),
      label: translateX("percentage_symbol"),
      disabled: false,
    },
  ];
};

export default financialService;
