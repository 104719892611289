import axiosService from "./axiosService";
import {
  isValidEmail,
  parseCnpjToDisplay,
  parseCpfToDisplay,
  returnOnlyNumbers,
} from "../utils/helpers";
import React from "react";
import { API_ENTITIES_URL } from "../settings/constant";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import JarvislyEntityProfileTag from "../components/jarvisly-components/items/JarvislyEntityProfileTag";
import { getPhoneProfileByNumber } from "../components/jarvisly-components/others/PhoneInput";

const entityService = {};

entityService.getEntityByField = async (
  value,
  field = "cpfCnpj",
  projection,
) => {
  const options = {
    returnAsObject: true,
    fullSearch: true,
    field: field,
    value: value,
    projection,
  };

  const [doc] = await entityService.getEntitiesByField(options);
  return doc;
};

entityService.getEntitiesByField = async (options) => {
  const {
    folder,
    dataType,
    dataProfile,
    field,
    value,
    projection, // Array of string (ex: ['name', 'birthdate', ...])
    returnAsObject = false,
    fullSearch = false, // false: use Regex to find by part of name, true: find 'full name' without regex.
  } = options;

  let url = `${API_ENTITIES_URL}/get-by-field`;

  url += `/${folder || null}`;
  url += `/${dataType || null}`;
  url += `/${dataProfile || null}`;
  url += `/${field || null}`;
  url += `/${encodeURIComponent(value) || null}`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    returnAsObject,
    headers: { "x-fullsearch": fullSearch },
  };
  return await axiosService.get(axiosOptions);
};

entityService.getEntityById = async (_id, projection) => {
  let url = API_ENTITIES_URL;

  if (projection) url += `/${projection}`;

  const axiosOptions = { url, _id };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

entityService.getEntitiesByPowerSearch = async (options) => {
  const {
    folder,
    dataType,
    dataProfile, // Array of string (ex: ['customer', 'provider', ...])
    value,
    projection, // Array of string (ex: ['name', 'birthdate', ...])
    returnAsObject = false,
    fullSearch = false, // false: use Regex to find by part of name, true: find 'full name' without regex.
  } = options;

  let url = `${API_ENTITIES_URL}/get-by-power-search`;

  url += `/${folder || null}`;
  url += `/${dataType || null}`;
  url += `/${dataProfile || null}`;
  url += `/${value || null}`;

  if (projection) url += `/${projection}`;

  const axiosOptions = {
    url,
    returnAsObject,
    headers: { "x-fullsearch": fullSearch },
  };
  return await axiosService.get(axiosOptions);
};

entityService.addEntity = async (data, profile) => {
  if ((!data?._id && !data?.dataType) || !profile) {
    return console.error("no dataType or dataProfile");
  }

  const cpfCnpj = data?.cpfCnpjNewCustomer || data?.cpf || data?.cnpj;
  const phoneProfile = getPhoneProfileByNumber(data.phone);

  const body = {
    ...data,
    uniqueKey: returnOnlyNumbers(cpfCnpj) || +new Date(),
    dataProfile: profile,
  };

  if (!data?._id) {
    if (data.dataType === "person") body.cpf = cpfCnpj;
    if (data.dataType === "company") body.cnpj = cpfCnpj;
  }

  if (data.phone && phoneProfile === "mobile") body.mobile = data.phone;
  if (data.phone && phoneProfile !== "mobile") body.phone = data.phone;

  const axiosOptions = {
    url: API_ENTITIES_URL,
    returnAsObject: true,
    _id: data?._id,
    data: body,
  };

  const [doc] = await axiosService.upsert(axiosOptions);

  return doc;
};

entityService.upsertEntity = async (entityId, data, headers) => {
  delete data._id;
  delete data._metadata;

  const [doc] = entityId
    ? await axiosService.put({
        _id: entityId,
        url: API_ENTITIES_URL,
        headers,
        data,
      })
    : await axiosService.post({
        url: API_ENTITIES_URL,
        headers,
        data,
      });

  return doc;
};

entityService.removeContact = async (documentId, contact) => {
  if (
    contact.dataProfile.length === 1 &&
    contact.dataProfile.includes("contact")
  ) {
    axiosService
      .delete({
        url: `{API_ENTITIES}`,
        _id: contact.entityId,
        headers: { "x-forcepermanentlydeletation": !contact.cpf },
      })
      .then();
  }

  const url = `${API_ENTITIES_URL}/add-to-set/${documentId}/$pull/moreContacts.entityId`;
  return await axiosService.put({
    url,
    data: { _id: contact.entityId },
  });
};

entityService.buildSelectedCustomer = (
  entity,
  xFieldId = "_id",
  xFieldName = "name",
  xFieldFullName = "fullName",
) => {
  return {
    key: entity?.[xFieldId],
    value: entity?.[xFieldName],
    title: entity?.[xFieldFullName],

    label: (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="text-uppercase font-weight-bold">
            {entity.dataType === "company" ? (
              <ShopOutlined className="padding-1" />
            ) : (
              <UserOutlined className="padding-1" />
            )}
            {entity?.[xFieldName]}
          </span>

          <span>
            <JarvislyEntityProfileTag record={entity} space="left" />
          </span>
        </div>

        <div>
          <span className="text-muted text-uppercase">
            ID: {entity?._metadata?.__documentCode}
          </span>

          {entity?.cpf || entity?.cnpj ? (
            <span>
              {" "}
              |&nbsp;
              {entity?.cpf
                ? parseCpfToDisplay(entity.cpf)
                : parseCnpjToDisplay(entity?.cnpj)}
            </span>
          ) : null}

          {entity.__city ? <span> | {entity.__city}</span> : null}
        </div>
      </>
    ),
  };
};

entityService.getEntityAllEmailsArr = (entity) => {
  const customerEmails = [];

  if (entity?.billingEmails) customerEmails.push(...entity.billingEmails);
  if (entity?.email) customerEmails.push(entity.email);
  if (entity?.moreContacts?.length > 0)
    customerEmails.push(...entity.moreContacts.map((c) => c.email));

  return [...new Set([...customerEmails.filter((e) => isValidEmail(e))])];
};

entityService.getBillingEmails = (entity) => {
  return entity?.billingEmails?.length > 0
    ? entity?.billingEmails
    : entity?.email
    ? [entity?.email]
    : [];
};

entityService.getAddress = (entity = {}) => {

  return {
    zip: entity?.zip,
    address1: entity?.address1,
    address2: entity?.address2,
    number: entity?.number,
    neighborhood: entity?.neighborhood,
    city: entity?.city,
    province: entity?.province,
    addressCoordinates: entity?.addressCoordinates,
    addressAdditionalInfo: entity?.addressAdditionalInfo,
    addressMetadata: entity?.addressMetadata,
  }
};

entityService.decorateEntity = (entity, level = 1, context = null) => {
  const level1 = {
    _id: entity?._id,

    selectedCustomer: entityService.buildSelectedCustomer(entity),
    name: entity?.name || entity.selectedEntity?.name,
    fullName: entity?.fullName || entity.selectedEntity?.fullName,
    cpfCnpj: entity?.cpf || entity?.cnpj,
    cpfCnpjNewCustomer: entity?.cpfCnpjNewCustomer,
    cpf: entity?.cpf,
    cnpj: entity?.cnpj,
    mobileDialCode: entity?.mobileDialCode,
    mobile: entity?.mobile || entity?.phone || null,
    billingEmails: entityService.getBillingEmails(entity),
    phoneDialCode: entity?.phoneDialCode,
    phone: entity?.phone,
    email: entity?.email,
    gender: entity?.gender,
    birthdate: entity?.birthdate,

    zip: entity?.zip,
    address1: entity?.address1,
    number: entity?.number,
    address2: entity?.address2,
    __city: entity?.__city,
    addressTitle: entity?.addressTitle,
    neighborhood: entity?.neighborhood,
    city: entity?.city,
    province: entity?.province,
    addressCoordinates: entity?.addressCoordinates,
    addressAdditionalInfo: entity?.addressAdditionalInfo,
    addressMetadata: entity?.addressMetadata,
  };

  const level2 = {
    uniqueKey: entity?.uniqueKey,
    dataProfile: entity?.dataProfile,
    dataType: entity?.dataType,
  };

  const level3 = {
    _metadata: entity?._metadata,
    backColor: entity?.backColor,
    foreColor: entity?.foreColor,
    attachmentIds: entity?.attachmentIds,
    status: entity?.status,
    moreContacts: entity?.moreContacts,
    moreAddress: entity?.moreAddress,
    lastName: entity?.lastName,
    firstName: entity?.firstName,
    parameters: entity?.parameters,
    _integrations: entity?._integrations,
  };

  let data = {};
  if (level === 1) data = { ...data, ...level1 };
  if (level === 2) data = { ...data, ...level2 };
  if (level === 3) data = { ...data, ...level3 };

  return context ? { [context]: data } : data;
};

export default entityService;
