import appService from "./appService";
import { toCapitalCase, translateX } from "../utils/helpers";
import axiosService from "./axiosService";
import dayjs from "dayjs";
import {
  API_ENTITIES_URL,
  API_RECEIVABLES_URL,
  BANK_ACCOUNT_CODE,
} from "../settings/constant";

const asaasService = {};

//*************************************************************************** //
// CUSTOMERS
//*************************************************************************** //

asaasService.createCustomer = async (accountCode, customer) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers`,
      headers: { "x-bankaccountcode": accountCode },
      data: decorateAsaasCustomerBody(customer),
    };

    const [doc] = await axiosService.post(axiosOptions);

    // update asaas customer id
    axiosOptions = {
      url: API_ENTITIES_URL,
      _id: customer?._id,
      data: {
        _integrations: {
          asaasCustomerId: doc.id,
        },
      },
    };
    axiosService.put(axiosOptions).then();

    return doc || null;
  } catch (error) {
    throw error;
  }
};

asaasService.updateCustomer = async (accountCode, customerId, customer) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers`,
      headers: { "x-bankaccountcode": accountCode },
      _id: customerId,
      data: decorateAsaasCustomerBody(customer),
    };

    const [doc] = await axiosService.put(axiosOptions);

    // update asaas customer id
    if (!customer?.parameters?._integrations?.asaasCustomerId) {
      axiosOptions = {
        url: API_ENTITIES_URL,
        _id: customer?._id,
        data: {
          _integrations: {
            asaasCustomerId: customerId,
          },
        },
      };
      axiosService.put(axiosOptions).then();
    }

    return doc || null;
  } catch (error) {
    throw error;
  }
};

asaasService.getCustomerByCpfCnpj = async (accountCode, cpfCnpj) => {
  try {
    let axiosOptions = {
      url: `/v3/asaas/customers?cpfCnpj=${cpfCnpj}`,
      headers: { "x-bankaccountcode": accountCode },
    };

    const [docs] = await axiosService.get(axiosOptions);
    return docs?.[0] || null;
  } catch (error) {
    throw error;
  }
};

//*************************************************************************** //
// PAYMENTS
//*************************************************************************** //

asaasService.generatePaymentUrl_OLD = async (bill, customer) => {
  const cpfCnpj = customer?.cpfCnpj || customer?.cpf || customer?.cnpj;

  try {
    let asaasCustomer = await asaasService.getCustomerByCpfCnpj(
      BANK_ACCOUNT_CODE,
      cpfCnpj,
    );

    if (!asaasCustomer?.id) {
      asaasCustomer = await asaasService.createCustomer(
        BANK_ACCOUNT_CODE,
        customer,
      );
    } else {
      asaasCustomer = await asaasService.updateCustomer(
        BANK_ACCOUNT_CODE,
        asaasCustomer.id,
        customer,
      );
    }

    // billing description
    const description = `${translateX("invoice_bill")} Nº ${
      bill._metadata.counter
    }     
${bill.description || ""}`;

    // BILLING TYPE
    let billingType = "UNDEFINED";
    if (bill?.billingType === "slip_pix") {
      billingType = "BOLETO";
    } else if (bill?.billingType === "pix") {
      billingType = "PIX";
    } else if (bill?.billingType === "credit_card") {
      billingType = "CREDIT_CARD";
    }

    // INTEREST
    const interest = { value: bill?.interestPercentage || 0 };

    // DISCOUNT
    const discount = {
      value:
        bill?.discountType === "value"
          ? bill?.discountValue || 0
          : bill?.discountPercentage || 0,
      type: bill?.discountType === "value" ? "FIXED" : "PERCENTAGE",
    };
    if (bill?.discountExpireDate && dayjs(bill?.discountExpireDate).isValid()) {
      discount.dueDate = dayjs(bill.dueDate)
        .endOf("day")
        .diff(dayjs(bill.discountExpireDate).endOf("day"), "days");
    }

    // FINE
    const fine = {
      value:
        bill?.fineType === "value"
          ? bill?.fineValue || 0
          : bill?.finePercentage || 0,
      type: bill?.fineType === "value" ? "FIXED" : "PERCENTAGE",
    };

    const body = {
      billingType,
      discount,
      interest,
      fine,
      // callback: {
      //   successUrl: 'https://www.infodesk.com.br/payment-done',
      //   autoRedirect: true,
      // },
      customer: asaasCustomer.id,
      value: bill.value,
      dueDate: dayjs(bill.dueDate).endOf("day").format("YYYY-MM-DD"),
      description,
      daysAfterDueDateToCancellationRegistration: 30,
      externalReference: bill._id,
      // installmentCount: bill?.installmentTotal || 1, // SOMENTE CASO DE PARCELAMENTO
      // totalValue: 3000,
      // installmentValue: 200,
      postalService: false,
    };

    let axiosOptions = {
      url: `/v3/asaas/payments`,
      headers: { "x-bankaccountcode": BANK_ACCOUNT_CODE },
      data: body,
    };

    const [asaasBill] = await axiosService.post(axiosOptions);

    // update receivable
    axiosOptions = {
      url: API_RECEIVABLES_URL,
      _id: bill?._id,
      data: {
        originalDueDate: asaasBill.originalDueDate,
        netValue: asaasBill.netValue,
        paymentUrl: asaasBill.invoiceUrl,
        bankSlipUrl: asaasBill?.bankSlipUrl,
        bankSlipNumber: asaasBill.nossoNumero,
        status: "waiting_for_payment",
        _integrations: {
          asaasPaymentId: asaasBill.id,
        },
        asaasInfo: {
          customer: asaasBill?.customer,
          createdAt: asaasBill?.dateCreated,
          updatedAt: asaasBill?.dateCreated,
          canBePaidAfterDueDate: asaasBill?.canBePaidAfterDueDate,
          pixTransaction: asaasBill?.pixTransaction,
          anticipated: false,
          anticipable: false,
          creditDate: null,
          estimatedCreditDate: null,
          lastInvoiceViewedDate: null,
          lastBankSlipViewedDate: null,
          postalService: false,
          custody: null,
          refunds: null,
          status: asaasBill?.status,
        },
      },
    };

    return await axiosService.put(axiosOptions);

    // if (!hideMessages) {
    // appService.message('s', 'payment_link_generated_success', 'payment', 5);
    // }
  } catch (error) {
    // appService.message('e', 'payment_link_generated_failure', 'payment', 5);
    appService.notification(
      "e",
      "payment_link_generated_failure",
      "payment",
      error?.response?.data?.message,
      0,
    );
    throw error;
  }
};

asaasService.generatePaymentUrl = async (bill, customer) => {
  const cpfCnpj = customer?.cpfCnpj || customer?.cpf || customer?.cnpj;

  try {
    let asaasCustomer = await asaasService.getCustomerByCpfCnpj(
      BANK_ACCOUNT_CODE,
      cpfCnpj,
    );

    if (!asaasCustomer?.id) {
      asaasCustomer = await asaasService.createCustomer(
        BANK_ACCOUNT_CODE,
        customer,
      );
    } else {
      asaasCustomer = await asaasService.updateCustomer(
        BANK_ACCOUNT_CODE,
        asaasCustomer.id,
        customer,
      );
    }

    // billing description
    const description = `${translateX("invoice_bill")} Nº ${
      bill._metadata.counter
    }     
${bill.description || ""}`;

    // BILLING TYPE
    let billingType = "UNDEFINED";
    if (bill?.billingType === "slip_pix") {
      billingType = "BOLETO";
    } else if (bill?.billingType === "pix") {
      billingType = "PIX";
    } else if (bill?.billingType === "credit_card") {
      billingType = "CREDIT_CARD";
    }

    // INTEREST
    const interest = { value: bill?.interestPercentage || 0 };

    // DISCOUNT
    const discount = {
      value:
        bill?.discountType === "value"
          ? bill?.discountValue || 0
          : bill?.discountPercentage || 0,
      type: bill?.discountType === "value" ? "FIXED" : "PERCENTAGE",
    };
    if (bill?.discountExpireDate && dayjs(bill?.discountExpireDate).isValid()) {
      discount.dueDate = dayjs(bill.dueDate)
        .endOf("day")
        .diff(dayjs(bill.discountExpireDate).endOf("day"), "days");
    }

    // FINE
    const fine = {
      value:
        bill?.fineType === "value"
          ? bill?.fineValue || 0
          : bill?.finePercentage || 0,
      type: bill?.fineType === "value" ? "FIXED" : "PERCENTAGE",
    };

    const body = {
      billingType,
      discount,
      interest,
      fine,
      // callback: {
      //   successUrl: 'https://www.infodesk.com.br/payment-done',
      //   autoRedirect: true,
      // },
      customer: asaasCustomer.id,
      value: bill.value,
      dueDate: dayjs(bill.dueDate).endOf("day").format("YYYY-MM-DD"),
      description,
      daysAfterDueDateToCancellationRegistration: 30,
      externalReference: bill._id,
      // installmentCount: bill?.installmentTotal || 1, // SOMENTE CASO DE PARCELAMENTO
      // installmentValue: bill.value / bill.installmentTotal,
      postalService: false,
    };

    if (bill?.installmentTotal > 1) {
      body.installmentCount = bill.installmentTotal;
      body.installmentValue = bill.value / bill.installmentTotal;
    }

    let axiosOptions = {
      url: `/v3/asaas/payments`,
      headers: { "x-bankaccountcode": BANK_ACCOUNT_CODE },
      data: body,
    };

    const [asaasBill] = await axiosService.post(axiosOptions);

/*
    console.log(
      "RETURN ASAAS CREDIT CARD =>",
      asaasBill,
      JSON.stringify(asaasBill),
    );

*/
    // update receivable
    axiosOptions = {
      url: API_RECEIVABLES_URL,
      _id: bill?._id,
      data: {
        originalDueDate: asaasBill.originalDueDate,
        netValue: asaasBill.netValue,
        paymentUrl: asaasBill.invoiceUrl,
        bankSlipLink: asaasBill?.bankSlipUrl,
        bankSlipNumber: asaasBill.nossoNumero,
        status: "waiting_for_payment",
        _integrations: {
          asaasPaymentId: asaasBill.id,
          asaasInstallmentKey: asaasBill?.installment, // only for credit card installments
        },
        asaasInfo: {
          customer: asaasBill?.customer,
          createdAt: asaasBill?.dateCreated,
          updatedAt: asaasBill?.dateCreated,
          canBePaidAfterDueDate: asaasBill?.canBePaidAfterDueDate,
          pixTransaction: asaasBill?.pixTransaction,
          anticipated: false,
          anticipable: false,
          creditDate: null,
          estimatedCreditDate: null,
          lastInvoiceViewedDate: null,
          lastBankSlipViewedDate: null,
          postalService: false,
          custody: null,
          refunds: null,
          status: asaasBill?.status,
        },
      },
    };

    return await axiosService.put(axiosOptions);
  } catch (error) {
    appService.notification(
      "e",
      "payment_link_generated_failure",
      "payment",
      error?.response?.data?.message,
      0,
    );
    throw error;
  }
};

export default asaasService;

function decorateAsaasCustomerBody(data) {
  return {
    name: toCapitalCase(data?.fullName),
    cpfCnpj: data?.cpf || data?.cnpj,
    email: data?.email,
    phone: data?.phone,
    mobilePhone: data?.phone,
    address: data?.address1,
    addressNumber: data?.number,
    complement: data?.address2,
    province: data?.neighborhood, // neighborhood
    postalCode: data?.zip,
    externalReference: data?._id,
    notificationDisabled: true,
    additionalEmails: data?.billingEmails.join(","),
    municipalInscription: data?.parameters?.municipalInscription || null,
    stateInscription: data?.parameters?.stateInscription || null,
    observations: "Cadastro criado via API",
    // groupName: 'Pedidos Online',
    company: data?.cnpj ? data?.name : null,
  };
}
